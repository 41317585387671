export const sidebarItems = [
  {
    title: "Пользователи",
    to: { name: "users-list" },
  },
  {
    title: "Тарифы",
    to: { name: "tarif" },
  },
  {
    title: "Настройки",
    to: { name: "settings" },
  },
  {
    title: "Отчеты",
    to: { name: "reports" },
  },
];

export function getSidebarItem(name) {
  return sidebarItems.find((si) => si.name === name);
}
